/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    var winWidth = $(window).width(),
        xs       = false,
        sm       = false,
        md       = false;

    /**
    * Mobile
    * Window width
    */

    function mobileCheck() {
        if ( winWidth >= '768' ) {
            md = true;
            sm = false;
            xs = false;
        } else if ( winWidth >= '576' ) {
            md = false;
            sm = true;
            xs = false;
        } else {
            md = false;
            sm = false;
            xs = true;
        }
    }

    mobileCheck();

    $(window).resize(function() {
        winWidth = $(window).width();
        mobileCheck();
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Delta = {

        // All pages
        'common': {

            init: function() {
                // JavaScript to be fired on all pages

                /**
                 * Site header
                 */

                var siteHeaderStyle = function() {
                    if ( 10 <= $(document).scrollTop() ) {
                        $('#site-header').addClass('is-not-top');
                    } else {
                        $('#site-header').removeClass('is-not-top');
                    }
                };

                siteHeaderStyle();

                $(window).on('scroll', function() {
                    siteHeaderStyle();
                });


                /**
                 * Site nav
                 */

                var closePrimaryNavSubMenus = function($this) {
                    if ( ! $this ) {
                        $('#menu-primary .sub-menu-active').removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').velocity('slideUp');
                    } else {
                        $('#menu-primary .sub-menu-active').not($this).removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').not($this.children('.sub-menu'))
                            .velocity('slideUp');
                    }
                },

                openPrimaryNavSubMenus = function($this) {
                    closePrimaryNavSubMenus($this);

                    $this.addClass('sub-menu-active');

                    $this.children('.sub-menu')
                        .velocity('slideDown');
                },

                closePrimaryNav = function() {
                    closePrimaryNavSubMenus();
                    $('body').removeClass('mobile-nav-is-active');
                    $('#site-nav .mobile-nav-trigger')
                        .removeClass('active');
                    $('#site-nav .mobile-nav-trigger').children('i')
                        .removeClass('la-close')
                        .addClass('la-navicon');
                },

                openPrimaryNav = function() {
                    $('body').addClass('mobile-nav-is-active');
                    $('#site-nav .mobile-nav-trigger')
                        .addClass('active');
                    $('#site-nav .mobile-nav-trigger').children('i')
                        .removeClass('la-navicon')
                        .addClass('la-close');
                };

                /* Not mobile */

                if ( md ) {

                    $(document).on({
                        mouseenter: function(e) {
                            $(this).children('.sub-menu').velocity('slideDown', { duration: 250 });
                            e.stopPropagation();
                        },
                        mouseleave: function(e) {
                            $(this).children('.sub-menu').velocity('slideUp', { duration: 150 });
                            e.stopPropagation();
                        }
                    }, '#menu-primary > li.menu-item');

                }

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .mobile-nav-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-nav-is-active') ) {
                        openPrimaryNav();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-nav-is-active', function(e) {
                    if ( $(e.target) !== $('#menu-primary') &&
                        ! $(e.target).parents('#menu-primary').length ) {
                        closePrimaryNav();
                        closePrimaryNavSubMenus();
                    }
                });

                // Submenu expand

                if ( ! md ) {

                    $(document).on(clickEvent(), '.menu-item-has-children .expand', function(e) {
                        if ( ! $(this).parent().hasClass('sub-menu-active') ) {
                            closePrimaryNavSubMenus($(this).parent());
                            openPrimaryNavSubMenus($(this).parent());
                        } else {
                            closePrimaryNavSubMenus();
                        }
                    });

                }


                /**
                 * Site search
                 */

                var closeSearch = function() {
                    $('body').removeClass('search-is-active');
                    $('#site-search input.search-field').blur();
                    $('#menu-primary .menu-item.search').find('i')
                        .removeClass('la-close')
                        .addClass('la-search');
                },

                openSearch = function() {
                    $('body').addClass('search-is-active');
                    $('#site-search input.search-field').focus();
                    $('#site-nav .mobile-search-trigger')
                        .addClass('active');
                    $('#menu-primary .menu-item.search').find('i')
                        .removeClass('la-search')
                        .addClass('la-close');
                },

                closeMobileSearch = function() {
                    $('body').removeClass('mobile-search-is-active');
                    $('#site-nav .mobile-search-trigger')
                        .removeClass('active');
                    $('#site-nav .mobile-search-trigger').children('i')
                        .removeClass('la-close')
                        .addClass('la-search');
                },

                openMobileSearch = function() {
                    $('body').addClass('mobile-search-is-active');
                    $('#site-nav .mobile-search-trigger').children('i')
                        .removeClass('la-search')
                        .addClass('la-close');
                };

                /* Not mobile */

                $(document).on(clickEvent(), '#menu-primary .menu-item.search a', function(e) {
                    e.preventDefault();
                    openSearch();
                });

                $(document).on(clickEvent(), 'body.search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                        $(e.target).parents('#site-search .close').length
                    ) {
                        closeSearch();
                    }
                });

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '#site-nav .mobile-search-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-search-is-active') ) {
                        openMobileSearch();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-search-is-active', function(e) {
                    if ( ! $(e.target).parents('#site-search').length ||
                       $(e.target).parents('#site-search .close').length
                    ) {
                        closeMobileSearch();
                    }
                });

                /* Window resize */

                $(window).resize(function() {

                    if ( md ) {
                        $('body')
                            .removeClass('mobile-nav-is-active')
                            .removeClass('mobile-search-is-active');
                        closePrimaryNavSubMenus();
                    }

                });


                /**
                 * Hero
                 */

                if ( $('.hero').length ) {

                    $('#hero-text').addClass('animated');
                    $('#hero-text strong').addClass('animated');

                }

                // Scroller

                if ( $('body').find('[data-scroller]').length ) {

                    var $scrollerArrow  = $('body').find('[data-scroller="arrow"]'),
                        $scrollerTarget = $($scrollerArrow.attr('href'));

                    $scrollerTarget.addClass('animated');

                    $(document).on(clickEvent(), '.' + $scrollerArrow[0].className, function(e) {
                        e.preventDefault();

                        // Wanted to use velocity here, but 'scroll' doesn't seem to work in 2.0?
                        // https://github.com/julianshapiro/velocity/issues/844
                        $('html, body').animate({
                            scrollTop: $scrollerTarget.offset().top
                        }, 800);
                    });

                    var scrollerWp = new Waypoint({
                        element: document.getElementById($scrollerTarget[0].id),
                        handler: function(direction) {
                            $(this.element).addClass('fade-in-down');
                        },
                        offset: '66%'
                    });

                }

                $('.banner .title').arctext({ radius: 450 });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                if ( $('.hero').length ) {

                    var heroTextWp = new Waypoint({
                        element: document.getElementById('hero-text'),
                        handler: function(direction) {
                            $(this.element)
                                .delay(2000).addClass('fade-in')
                                .delay(2500).find('strong').addClass('fade-in-down')
                                .delay(5000).find('u').addClass('underlined');
                        },
                        offset: '75%'
                    });

                    var heroBannerWp = new Waypoint({
                        element: document.getElementById('hero-banner'),
                        handler: function(direction) {
                            $(this.element).delay(3000).find('.product-display').addClass('active');
                        },
                        offset: '100%'
                    });

                }
            }

        }, // common

        'home': {

            init: function() {
            }

        },

        'about_us': {

            init: function() {

                var closeChildPages = function($this) {
                    if ( ! $this ) {
                        $('.child-page-link').removeClass('active');
                        $('.child-page-content').slideUp('200');
                    } else {
                        $('.child-page-link').not($this).removeClass('active');
                        $('.child-page-content').not($this).slideUp('200');
                    }
                },

                openChildPages = function($this) {
                    closeChildPages($this);

                    var pageContent = $this.data('content');

                    $this.addClass('active');
                    $('#' + pageContent).slideDown('300');
                };

                $(document).on(clickEvent(), '.child-page-link', function(e) {
                    e.preventDefault();

                    if ( ! $(this).hasClass('active') ) {
                        openChildPages($(this));
                    } else {
                        closeChildPages();
                    }
                });
            }
        },

        'single': {

            init: function() {

                // Title move

                var moveTitle = function() {
                    if ( ! md ) {
                        $('.single-header').prependTo('.col-single-imagery');
                    } else {
                        $('.single-header').prependTo('.single-content');
                    }
                };

                if ( $('.single-header').length ) {

                    moveTitle();

                    $(window).resize(function() {
                        moveTitle();
                    });

                }

                // Product certs

                if ( $('#certs-list').length ) {

                    var $certsList = $('#certs-list');

                    $certsList.addClass('animated');

                    var certsListWp = new Waypoint({
                        element: document.getElementById('certs-list'),
                        handler: function(direction) {
                            $(this.element).addClass('fade-in-up');
                        },
                        offset: '75%'
                    });

                }

                // Buy now

                if ( $('.buy-now').length ) {

                    var $buyNow = $('.buy-now');

                    $buyNow.addClass('animated');

                    var buyNowWp = new Waypoint({
                        element: document.getElementById('buy-now'),
                        handler: function(direction) {
                            $(this.element).addClass('fade-in-down');
                        },
                        offset: '75%'
                    });

                }

                /* Carousels */

                // Related products/recipes carousels

                $('.related-carousel').owlCarousel({
                    items:         3,
                    autoplay:      false,
                    loop:          true,
                    nav:           true,
                    navText:       [
                        '<i class="la la-chevron-left"></i><span class="sr-only">Previous</span>',
                        '<span class="sr-only">Next</span><i class="la la-chevron-right"></i>'
                    ],
                    mouseDrag:     false,
                    responsive:    {
                        0: {
                            items: 1
                        },
                        576: {
                            items: 2
                        },
                        768: {
                            items: 3
                        }
                    }
                });

            }

        }


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
